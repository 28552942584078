<template>
  <layout width="540">
    <template slot="header">
      {{ property.property }}
    </template>
    <v-form />
    <div class="text--secondary">Name: {{ property.property }}</div>
    <div class="text--secondary">Value: {{ property.value }}</div>
    <div class="text--secondary">
      Time: {{ convertDate(property.recordedAt) }}
    </div>
    <template slot="footer">
      <v-spacer />
      <v-btn color="primary" text @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn color="primary" text @click="copy">
        Copy
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';

import { useCopy } from '@/compositions/copy';

import { convertDate } from '@/utils';

export default {
  name: 'PropertyInfo',
  components: {
    Layout
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    onSubmit: {
      type: Function,
      default: () => ({})
    }
  },
  setup(props) {
    const { copyWithAlert } = useCopy();

    const copy = () => {
      copyWithAlert(JSON.stringify(props.property));
    };

    return {
      copy,
      convertDate
    };
  }
};
</script>
